.main {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 5rem;
  min-height: 100vh;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 4rem 0;
  z-index: 10000;
}

.header::before {
  background: linear-gradient(
    to left,
    rgba(1, 65, 255, 0),
    rgba(1, 65, 255, 0),
    rgb(21 161 137 / 30%)
  );
  border-radius: 50%;
  width: 795px;
  height: 160px;
  margin-left: -75%;
}

.header::before {
  content: "";
  left: 50%;
  position: absolute;
  filter: blur(30px);
  transform: translateZ(0);
}
.header h1 {
  font-family: Inter, sans-serif;
  font-size: 4rem;
  font-weight: 800;
  letter-spacing: 2px;
  text-shadow: 0 0 5px #fff, 0 0 15px #fff;
  /* 0 0 30px rgba(1, 65, 255, 0.3), 0 0 40px rgba(1, 65, 255, 0.3),
    0 0 50px rgba(1, 65, 255, 0.3), 0 0 60px rgba(1, 65, 255, 0.3),
    0 0 70px rgba(1, 65, 255, 0.3); */
}
.logo {
  position: relative;
}

/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {
  .card:hover {
    background: rgba(var(--card-rgb), 0.1);
    border: 1px solid rgba(var(--card-border-rgb), 0.15);
  }

  .card:hover span {
    transform: translateX(4px);
  }
}

/* Mobile */
@media (max-width: 700px) {
  .header {
    padding: 8rem 0 6rem;
  }

  .header::before {
    transform: none;
    height: 300px;
  }
}

@media (prefers-color-scheme: dark) {
  .logo {
    filter: invert(1) drop-shadow(0 0 0.3rem #ffffff70);
  }
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
