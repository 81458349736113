:root {
  --accent_color: #1daa61;
  --light_gray: #272727;
  --dark_gray: #202020;
  --sent_message_color: #005c4b;
  --sent_message_border_color: #106656;
  --received_message_color: #353535;
  --received_message_border_color: "3e3e3e";
  --chat_hover_background: #383838;
  --chat_selected_background: #515151;
}

@font-face {
  font-family: "Helvetica";
  src: url("../fonts/Helvetica.ttf");
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: rgb(255, 255, 255);
  background: black;
}

a {
  color: inherit;
  text-decoration: none;
}

.niceScrollbar::-webkit-scrollbar {
  width: 3px !important;
}

.niceScrollbar::-webkit-scrollbar-thumb {
  background-color: #9f9f9f !important;
  border-radius: 20px !important;
  border-color: transparent !important;
}

svg {
  cursor: pointer;
}

.emoji-text {
  font-family: "Noto Color Emoji", sans-serif;
  font-size: 1rem;
}
.logo {
  -webkit-user-drag: none;
}
/* @media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
} */
